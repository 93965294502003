@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&family=Roboto:ital,wght@0,300;0,400;0,500;1,400&display=swap');


// COLORS 

$white-color: #FEF6F6;
$red-color: #EB6C66;
$dark-red-color: #F05942;
$black-color: #0b0d10;
$gray-color: #0F1115;
$light-gray-color: #15191E;
$light-pink-color: #FADCDB;

// TYPOGRAPHY

$caption-size: clamp(0.73rem, calc(0.70rem + 0.12vw), 0.80rem);
$body-size: clamp(0.88rem, calc(0.83rem + 0.22vw), 1.00rem);
$h4-size: clamp(1.05rem, calc(0.98rem + 0.35vw), 1.25rem);
$h3-size: clamp(1.26rem, calc(1.15rem + 0.53vw), 1.56rem);
$h2-size: clamp(1.51rem, calc(1.36rem + 0.77vw), 1.95rem);
$h1-size: clamp(1.81rem, calc(1.60rem + 1.09vw), 2.44rem);
$step-5: clamp(2.18rem, calc(1.87rem + 1.52vw), 3.05rem);
$step-6: clamp(2.61rem, calc(2.19rem + 2.09vw), 3.82rem);
$display-size: clamp(3.14rem, calc(2.57rem + 2.84vw), 4.77rem);


*, p {
    margin: 0;
    padding: 0;
}
  
body {
    background-color: $black-color;
    color: $white-color;
    font-weight: 400;

    &.lock-scroll { overflow: hidden; }
}

a { 
    text-decoration: none; display: inline-block; color: $white-color;
    transition: all .2s ease-in-out!important;
    &:hover {
        color: $light-pink-color;
    }
}

img {     
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

h1, h2, h3, h4, .display { font-family: 'Montserrat', sans-serif!important; margin:0; }
p { font-family: 'Roboto', sans-serif; font-size: $body-size; font-weight: 400; }
label { font-family: 'Roboto', sans-serif; font-size: $body-size; font-weight: 500;  color: $red-color;}
span { font-family: 'Roboto', sans-serif; font-size: $caption-size;  font-weight: 700; }

h1 { font-size: $h1-size; font-weight: 800; }
h2 { font-size: $h2-size; font-weight: 800; }
h3 { font-size: $h3-size; font-weight: 800; color: $red-color; }
h4 { font-size: $h4-size; font-weight: 500; }
.display { font-size: $display-size; font-weight: 800; }

hr {
    width: 100%;
    border: 0;
    border-bottom: 1px solid $red-color;
    border-radius: 50%;
    opacity: 1;

    &.dashed {
        border-bottom: dashed 2px $light-gray-color!important;
        border-radius: 0;
    }
}

.caption { font-family: 'Roboto', sans-serif; font-size: $caption-size; font-weight: 400; font-style: italic; color: $light-pink-color; }

.link-text { 
    font-family: 'Roboto', sans-serif;
    font-size: $body-size;
    font-weight: 400;
    font-style: italic;
    color: $white-color;
    text-decoration: none;
    
    transition: all .2s ease-in-out!important;

    &:hover { color: $light-pink-color!important; }
}

.link-icon {
    .link-icon-path { fill: $red-color; transition: all .2s ease-in-out; }

    &:hover { .link-icon-path { fill: $dark-red-color; } }
}


// HERO

.hero-container {
    position: relative;
    min-height: 700px;
    max-height: 1300px;
    padding: 0!important;
    margin: 0;
    
    .hero-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img { width: 100%; max-width: 2500px; object-fit: cover; }
}

// MISCELLANEOUS

.border-radius-portfolio { border-radius: 15px; }

.auto-width { width: 0; min-width: 100%; }

// PAGES

    // HOME

    .curved-line {
        width: 80%;
    }
    
    .icon-list {

        min-height: calc(3 * $step-6);
        a {
            -webkit-filter: grayscale(100%); // Safari 6.0 - 9.0
            filter: grayscale(100%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc($step-6 + 40px);
    
            transition: all .3s ease-in-out;
    
            .skills-icon { width: 80%; transition: all .3s ease-in-out; }
    
            &:hover {
                -webkit-filter: none;
                filter: none;
                .skills-icon { width: 100%; }
            }
    
        }
    }

    // CONTACT

    .contact-img-mobile {
        width: 80%;
        max-width: 450px;
        height: auto;
        margin: 0 auto;
    }

    .contact-portfolio {
        position: relative;


        @media screen and (min-width: 992px) {
            height: 90vh;
            min-height: 780px;
            max-height: 1200px;
        }


        form {

            width: 80%;
            max-width: 450px;
            margin: 0 auto;

            .form-control {
                border: 0;
                color: $white-color;
                max-height: 400px;
                background-color: rgba($color: $light-gray-color, $alpha: 0.8);
            }

            @media screen and (min-width: 992px) {
                position: absolute;
                
                left: 50%;
                top: 50%;
                transform: translate(-106%, -50%);
                z-index: 2;
                
                .form-control {
                    background-color: rgba($color: $black-color, $alpha: 0.8);
                    box-shadow: inset 0px 9px 6px -1px rgba(0, 0, 0, 0.35);
                }
            }

        }
        img { position: absolute; height: 100%; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 1;}
    }

    // PORTFOLIO

    .project-list img, .project img { width: 100%; }

    .project {
        height: fit-content;
        background: $gray-color;

        a { color: $red-color; }

        .stack-icon { 
            max-width: 35px;
            -webkit-filter: grayscale(100%); // Safari 6.0 - 9.0
            filter: grayscale(100%);
        }
    }

    .project-logo {
        position: relative;
        transition: all .2s ease-in-out!important;

        a { 
            width: 100%; height: 100%;
            img { max-width: 100%; }
        }

        &:hover {filter: grayscale(1);}

        a::after {
            content: "";
            background-image: url("../assets/svg/icons/external-link-icon.svg");
            background-size: 100% 100%;
            border-radius: 5px;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        @media screen and (min-width: 992px) { aspect-ratio : 1 / 1; }
    }

    .project-line:nth-last-child(1){
        display: none;
    }

    .project-stack { @media screen and (max-width: 992px) { padding: 0 } }

// SECTIONS

section { 
    margin-top: 8%; 

    @media screen and (max-width: 992px) {
        margin-top: 80px;
    }
}