// HEADER

header {
    position: sticky;
    overflow: hidden;
    z-index: 9999;
    top: 0;
    left: 0;
    min-height: 80px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    .navbar-collapse button {
        svg { top: 45%; }
    }

    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(11, 13, 16, .8);
    }

    &.header-mobile::before { background: rgba(11, 13, 16, 1); }

    .navbar-nav-mobile {
        height: 100vh;
        overflow: hidden;
    }

}

// Footer

footer {
    border-top: 1px solid $light-gray-color;
    margin-top: 8%; 

    @media screen and (max-width: 992px) { margin-top: 80px; }

    p { font-weight: 300; }
}

// Header & Footer

.nav-link {
    position: relative;
    color: $white-color!important;
    transition: all .2s ease-in-out!important;

    &:hover { color: $light-pink-color!important; }

    &.active::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 80%;
        height: 2px;
        background-color: $red-color;
        border-radius: 50%;    
        transform: translate(-50%);
    }

}