// BUTTONS

.btn-portfolio, .btn-portfolio-arrow {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: $white-color;
  background: transparent;
  padding: 12px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  transition: all .3s ease-in-out;

  p { z-index: 2;}

  svg .st0 { fill: $red-color; transition: all .3s ease-in-out; }

  &:hover {

    svg .st0 { fill: $dark-red-color; }
  }
}

.btn-portfolio {

  gap: 12px;

  svg {
    position: absolute;
    width: 110%;
    left: 58%;
    top: 40%;
    transform: translate(-50%, -50%);
  };
}

.btn-portfolio-arrow {

  &.arrow-left img {
    transform: rotate(180deg);
  }

  svg {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  };
}

// Card

.card-portfolio {
  background: $gray-color;
  position: relative;

  &:before {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      left: 10px;
      right: 10px;
      height: 8px;
      background: url("../assets/img/hat_paper.svg");
      background-size: cover;

      @media screen and (max-width: 576px) {
          top: -6px;
          height: 6px;
      }
  }
  
  &:after {
      content: "";
      position: absolute;
      display: block;
      bottom: -8px;
      left: 10px;
      right: 10px;
      height: 8px;
      background: url("../assets/img/hat_paper.svg");
      background-size: cover;
      transform: rotate(180deg);

      @media screen and (max-width: 576px) {
          bottom: -6px;
          height: 6px;
      }
  }

  .card-price {
      
      span {
          font-family: 'Montserrat', sans-serif!important;
          font-weight: 800;
          font-size: $h2-size;
      }
  }

  .subtitle { font-size: $caption-size; }
}

// CTA Banner

.cta-banner {
  background: $red-color;

  h3 { color: $white-color;}

  .btn-portfolio {
    color: $red-color;

    svg .st0 { fill: $white-color; }

    &:hover { svg .st0 {fill: $gray-color!important; }}
  }
}
  
// LABEL

.label-portfolio {
  background: $light-pink-color;
  color: $black-color;
  padding-block: 4px;
  padding-inline: 16px;
}

// Last Project

.last-project {

  background: $gray-color;

  .last-project-img, .text-start {
    flex-basis: 50%;
  }

  .last-project-img {

    @media screen and (min-width: 992px) {
    width: 50%;
    }

    img { max-width: 100%;}
  }
}

// Question

.question-portfolio {
  
  span { font-size: $body-size; color: $red-color;}

  .question-dots {
    button {
      background-color: $light-gray-color;
      border-radius: 50%;
      border: 0;
      width: 12px;
      height: 12px;
      margin-inline: 4px;

      &.active {
        background-color: $white-color;
      }
    }
  }

  .question-text { min-height: 120px;}
}